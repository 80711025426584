import { Box, BoxProps, Button, Chip, Collapse, Grid, Paper, Stack, Typography } from "@mui/material";
import { useRef, useState } from "react";
import { SearchResults } from "../../../models/search";
import VerbatimCardFeedback from "./VerbatimCardFeedback";
import SubdirectoryArrowRightIcon from "@mui/icons-material/SubdirectoryArrowRight";

type TextSectionType = {
  title: string;
  content: string;
};

const TextSection = ({ title, content, ...props }: TextSectionType & BoxProps): JSX.Element => {
  return (
    <Box {...props} sx={{ marginBottom: "12px", ...props.sx }}>
      <Typography fontSize="10px" sx={{ color: "text.disabled", textTransform: "uppercase" }}>
        {title}
      </Typography>
      <Typography fontSize={14}>{content}</Typography>
    </Box>
  );
};

const sentimentColorMap: Record<string, string> = {
  positive: "#74AA50",
  neutral: "#8DC8E8",
  negative: "#BA3E52",
};

type VerbatimCardType = {
  record: SearchResults;
};

export default function VerbatimCard({ record }: VerbatimCardType): JSX.Element {
  const [expanded, setExpanded] = useState(false);
  const summaryBoxRef = useRef<HTMLDivElement>();

  const hasHiddenSummaryContent =
    (summaryBoxRef.current?.scrollHeight ?? 0) > (summaryBoxRef.current?.clientHeight ?? 0);

  const hasHiddenSummaryComponent = !!record.text && !!record.text_summary;

  const hasHiddenAdditionalInfo = !!record.submitted_by || !!record.account_name;

  const allowCollapseExpand = hasHiddenSummaryContent || hasHiddenSummaryComponent || hasHiddenAdditionalInfo;

  return (
    <Paper elevation={1} sx={{ borderRadius: "12px", overflow: "hidden" }}>
      <Box>
        <Stack direction="row" justifyContent="space-between">
          <Typography
            fontWeight={500}
            fontSize="10px"
            ml="24px"
            paddingTop="2px"
            sx={{ display: "flex", alignItems: "center", lineHeight: "20px" }}
          >
            {record.data_source}
          </Typography>
          <Chip
            label={record.sentiment || "unknown"}
            sx={{
              backgroundColor: record.sentiment
                ? sentimentColorMap[record.sentiment.toLowerCase()]
                : "rgba(255, 255, 255, 0.5",
              color: Object.keys(sentimentColorMap).includes(record.sentiment?.toLowerCase())
                ? "white"
                : "primary.dark",
              borderRadius: "0 0 0 8px",
              textTransform: "uppercase",
              letterSpacing: "0.05em",
              lineHeight: 1,
              width: "100px",
              height: "24px",
            }}
          />
        </Stack>
        <div style={{ padding: "14px 0px", marginLeft: "24px" }}>
          <Box
            sx={{
              bgcolor: "red",
              mx: "8px",
              backgroundColor: "primary.light",
              fontSize: "11px",
              fontWeight: "600",
              padding: "10px",
              display: "flex",
              borderRadius: "8px",
              flexDirection: "column",
              width: "fit-content",
              minWidth: "100px",
            }}
          >
            <Typography fontSize={"12px"}>{record.topics || "No Topic"}</Typography>
            {record.subtopic && (
              <Box sx={{ display: "flex", mt: "5px" }}>
                <SubdirectoryArrowRightIcon sx={{ fontSize: "14px" }} />
                <Typography fontSize={"12px"}>{record.subtopic}</Typography>
              </Box>
            )}
          </Box>

          {/* <Chip
            label={record.topics || "No Topic"}
            sx={{
              backgroundColor: "primary.light",
              color: "primary.dark",
              mr: "8px",
              fontSize: "13px",
              fontWeight: "600",
              lineHeight: 1,
            }}
          /> */}
        </div>
        <Box px="24px">
          <Collapse collapsedSize={"5em"} in={expanded}>
            <Box ref={summaryBoxRef}>
              {record.text_summary && (
                <TextSection title="Summary" content={record.text_summary} sx={{ lineHeight: "19.5px" }} />
              )}
              <TextSection
                // Show the "Verbatim" portion if "Read More" is expanded, or if there is no summary
                sx={{
                  visibility: expanded || !record.text_summary ? undefined : "hidden",
                  letterSpacing: "0.0011em",
                  lineHeight: "19.5px",
                }}
                title="Verbatim"
                content={record.text}
              />
            </Box>
          </Collapse>
          <Collapse collapsedSize={0} in={expanded}>
            {record.submitted_by && (
              <Stack direction="row" justifyContent="space-between">
                <Typography
                  fontSize="10px"
                  color="rgba(90, 91, 93, 0.6)"
                  textTransform="uppercase"
                  marginBottom="4px"
                  lineHeight="23px"
                >
                  Submitted By
                </Typography>
                <Typography fontSize="13px" textAlign="right" lineHeight="23px">
                  {record.submitted_by}
                </Typography>
              </Stack>
            )}
            {record.account_name && (
              <Stack direction="row" justifyContent="space-between">
                <Typography fontSize="10px" color="rgba(90, 91, 93, 0.6)" textTransform="uppercase" marginTop="4px">
                  Account Name
                </Typography>
                <Typography fontSize="13px" marginTop="3px" textAlign="right" maxWidth="120px">
                  {record.account_name}
                </Typography>
              </Stack>
            )}
          </Collapse>
          <Grid container>
            <Grid item flexGrow={1} />
            <Grid item xs="auto">
              <Button
                variant="text"
                size="small"
                sx={{
                  fontSize: "10px",
                  color: "primary.dark",
                  marginTop: "8px",
                  minWidth: 0,
                  display: "flex",
                  justifyContent: "flex-end",
                  // Hide the "Show More" button if the whole content of the Verbatim card is visible while not expanded
                  visibility: allowCollapseExpand || expanded ? undefined : "hidden",
                }}
                onClick={() => setExpanded(!expanded)}
              >
                {`Show ${expanded ? "Less" : "More"}`}
              </Button>
            </Grid>
          </Grid>
        </Box>
      </Box>
      <VerbatimCardFeedback record={record} record_id={record._id} />
    </Paper>
  );
}
