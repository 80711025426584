import { useAtom } from "jotai";
import { useState, useEffect } from "react";
import { ReadyState } from "react-use-websocket";
import { useSocket } from "../../../api/useSocket";
import { useFilterContext } from "../../../contexts/FilterProvider";
import { useVerbatims } from "../../../contexts/VerbatimsProvider";
import emitSnackbar from "../../../emitSnackbar";
import {
  TriggerExecSummaryAtom,
  ExecSummaryLoadingAtom,
  ExecSummaryEnableTooltipAtom,
  ExecSummaryDisableButtonAtom,
  ExecSummaryHasContentAtom,
  SocketRequest,
  SocketResponse,
  ExecSummaryContentAtom,
  DocumentMapping,
} from "./state/ExecutiveSummaryState";
import { FilterResetAtom } from "../../../contexts/FilterResetAtom";

export default function useExecutiveSummaryStore() {
  const [allowFeedback, setAllowFeedback] = useState(false);
  const [triggerExecSummary, setTriggerExecSummary] = useAtom(TriggerExecSummaryAtom);
  const [execSummaryLoading, setExecSummaryLoading] = useAtom(ExecSummaryLoadingAtom);
  const [execSummaryEnableTooltip, setExecSummaryEnableTooltip] = useAtom(ExecSummaryEnableTooltipAtom);
  const [execSummaryDisableButton, setExecSummaryDisableButton] = useAtom(ExecSummaryDisableButtonAtom);
  const [execSummaryHasContent, setExecSummaryHasContent] = useAtom(ExecSummaryHasContentAtom);
  const [filterReset] = useAtom(FilterResetAtom);

  const { numRecords } = useVerbatims();
  const { filterState } = useFilterContext();

  const { lastJsonMessage, sendJsonMessage, readyState } = useSocket<SocketRequest, SocketResponse>(
    "executive_summary",
  );

  const [summary, setSummary] = useAtom(ExecSummaryContentAtom);

  const requestExecutiveSummary = () => {
    if (!filterState) return;

    setExecSummaryLoading(true);
    sendJsonMessage({ search_request: filterState });
  };

  const resetSummaryState = () => {
    setSummary({});
    setExecSummaryHasContent(false);
    setAllowFeedback(false);
  };

  useEffect(() => {
    if (triggerExecSummary > 0) {
      requestExecutiveSummary();
      setTriggerExecSummary(0);
    }
  }, [triggerExecSummary]);

  useEffect(() => {
    if (["chat.answer", "chat.initial"].includes(lastJsonMessage?.type)) {
      setSummary({
        ...summary,
        ...lastJsonMessage?.data,
      });
    } else if (lastJsonMessage?.type === "chat.bullet") {
      const message = lastJsonMessage?.data as unknown as { bullet_id: number; token: string };

      setSummary((_summary) => {
        const summary = { ..._summary };

        if (!summary.bullets) {
          summary.bullets = [];
        }

        const bullet_item = summary.bullets.find((_bullet) => _bullet.bullet_id == message.bullet_id);

        if (!bullet_item) {
          summary.bullets.push({ bullet_id: message.bullet_id, text: message.token, source_ids: [] });
        } else {
          bullet_item.text += message.token;
        }

        return summary;
      });
    } else if (lastJsonMessage?.type === "chat.quantifier") {
      const data = lastJsonMessage?.data as unknown as { mapping: DocumentMapping };

      if (data.mapping.document_ids.length) {
        setSummary((_summary) => {
          const summary = { ..._summary };

          // This puts a non null array back in place when the search is changed/cleared, since using a fixed "empty summary" object lead to weird side effects
          if (!summary.bullets) {
            summary.bullets = [];
          }

          // New append based logic so that the backend can stream incomplete batches of document_ids
          const bullet_item = summary.bullets?.find((_bullet) => _bullet.bullet_id == data.mapping.bullet_id);
          if (bullet_item) {
            bullet_item.source_ids = [...bullet_item.source_ids, ...data.mapping.document_ids];
          } else {
            console.warn("No bullet exists for payload:", data.mapping);
          }

          return summary;
        });
      }
    } else if (lastJsonMessage?.type === "chat.complete") {
      setExecSummaryLoading(false);
      setAllowFeedback(true);
      console.debug("summary:", { summary });
    } else if ((lastJsonMessage as unknown as { message: string })?.message === "Internal server error") {
      emitSnackbar("Something went wrong, please try again in a moment", "warning");
      setExecSummaryLoading(false);
    }
  }, [lastJsonMessage]);

  useEffect(() => {
    if (Boolean(summary.answer) && !execSummaryHasContent) {
      setExecSummaryHasContent(true);
    }
  }, [summary]);

  // Trigger clear on filter change ONLY when there are no join_ids are selected
  useEffect(() => {
    // Only reset the summary output when the filter by ID property is empty
    if ((filterState?.join_ids ?? []).length == 0) {
      resetSummaryState();
    }
  }, [filterState]);

  // Force trigger reset on [filterReset]
  useEffect(() => {
    resetSummaryState();
  }, [filterReset]);

  // On state change determine if the Generate Summary button should be enabled
  useEffect(() => {
    const currExecSummaryEnableTooltip = !numRecords || numRecords < 1 || ReadyState[readyState] !== "OPEN";
    if (execSummaryEnableTooltip != currExecSummaryEnableTooltip) {
      setExecSummaryEnableTooltip(currExecSummaryEnableTooltip);
    }

    const currExecSummaryDisableButton = execSummaryEnableTooltip || execSummaryLoading;
    if (execSummaryDisableButton != currExecSummaryDisableButton) {
      setExecSummaryDisableButton(currExecSummaryDisableButton);
    }
  }, [readyState, numRecords, execSummaryEnableTooltip, execSummaryLoading]);

  return {
    summary,
    setSummary,
    allowFeedback,
  };
}
