export type AppEnv = "local" | "dev" | "qa" | "prod" | "all";

export interface FeatureFlagConfig {
  feature: string;
  env: AppEnv[];
}

export const Feature = {
  MedInfo: "med-info",
  CustomTabs: "custom-tabs",
  Retros: "retros",
  DocumentSummary: "document-summary",
  RoleSwitching: "role-switching",
};

export const featureFlagConfig: FeatureFlagConfig[] = [
  { feature: Feature.MedInfo, env: ["local"] },
  { feature: Feature.CustomTabs, env: ["all"] },
  { feature: Feature.Retros, env: ["all"] },
  { feature: Feature.DocumentSummary, env: ["local", "dev"] },
  { feature: Feature.RoleSwitching, env: ["local", "dev"] },
];
