import { Box, Stack, Typography } from "@mui/material";
import DeepchatNavigationTabs from "../DeepchatNavigationTabs";
import FileUploadPanel from "./FileUploadPanel";
import RecentDocuments from "./RecentDocuments";
import { History } from "@mui/icons-material";

export default function SummarizeDocument() {
  return (
    <>
      <Box flex={1} minHeight="100%" marginX="auto" maxWidth="48rem">
        <Stack
          className="QuestionInput"
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={4}
          sx={{ minHeight: "100%" }}
        >
          <Typography variant="h3" fontWeight="bold" color={"rgb(0, 48, 135)"}>
            Deepchat
          </Typography>
          <Box sx={{ display: "flex", flexDirection: "column", width: "100%" }}>
            <DeepchatNavigationTabs />
            <FileUploadPanel />
          </Box>
        </Stack>
      </Box>
      <Box sx={{ width: "24vw", px: "2em", ml: "2em", borderLeft: "1px solid #eee", overflow: "auto" }}>
        <RecentDocuments />
      </Box>
    </>
  );
}
