import { ArrowForward, AutoFixHigh, FilterList } from "@mui/icons-material";
import { Box, Button, CircularProgress, InputBase, Paper, Tooltip } from "@mui/material";
import { useQuestionInputStore } from "../../../store/home/useQuestionInputStore";
import { grey } from "../../../colors";
import QueryEnhancePanel from "./QueryEnhancePanel";
import { useQuestionEnhancer } from "../../../store/home/useQuestionEnhancer";

// Simple validation method to block the majority of basic nonsense being submitted to the query enhancer
export const isValidQuestion = (question: string) => {
  const text = String(question);
  const words = text.split(" ");
  return text.length > 8 && words.length > 2 && words.some((_word) => _word.length > 2);
};

export interface QuestionInputProps {
  placeholder?: string;
  hideEnhanceButton?: boolean;
}

export default function QuestionInput(
  { placeholder, hideEnhanceButton }: QuestionInputProps = { hideEnhanceButton: false },
) {
  const {
    disableFilters,
    loading,
    question,
    LeftCallout,
    RightCallout,
    submitQuestion,
    orientation,
    setQuestion,
    setHideFilterDrawer,
    hideFilterDrawer,
  } = useQuestionInputStore();

  const { enhanceQuestion, closeEnhancerPanel, resetEnhancerState, QE } = useQuestionEnhancer();

  const handleSubmitQuestion = (question: string) => {
    closeEnhancerPanel();
    resetEnhancerState();

    submitQuestion(question);
  };

  return (
    <Paper
      component="form"
      sx={{
        p: "2px 4px",
        display: "flex",
        width: "100%",
        flexDirection: disableFilters ? "row" : "column",
      }}
    >
      <InputBase
        sx={{ ml: 1, flex: 1, p: disableFilters ? "" : "0.5rem", mt: 1 }}
        disabled={loading}
        multiline
        placeholder={placeholder || "Select filters to narrow down the data, then ask a question..."}
        value={question}
        onKeyDown={(event) => {
          if (event.key === "Enter") {
            // Block page from reloading on "Enter"
            event.preventDefault();
            handleSubmitQuestion(question);
          }
        }}
        onChange={(event) => {
          setQuestion(event.target.value);
        }}
      />
      <QueryEnhancePanel sx={{ display: QE.open ? "block" : "none" }} onSelectOption={setQuestion} />
      <Box display="flex" justifyContent="space-between" alignItems="center" marginLeft="1rem">
        {!disableFilters && (
          <LeftCallout placement={{ top: "100%", left: "100%" }} x="left" y="top" axis="y">
            <Button onClick={() => setHideFilterDrawer(!hideFilterDrawer)} size="small" startIcon={<FilterList />}>
              Filter
            </Button>
          </LeftCallout>
        )}
        {loading ? (
          <CircularProgress sx={{ p: "0.25em", m: "4px" }} />
        ) : (
          <Box display="flex">
            {!hideEnhanceButton && (
              <Tooltip
                disableHoverListener={!question || isValidQuestion(question)}
                title="Please enter a valid question with 3 or more words"
              >
                <span>
                  <Button
                    disableElevation
                    disabled={!isValidQuestion(question)}
                    variant="contained"
                    aria-label="enhance"
                    sx={{ padding: "8px", margin: "4px", minWidth: "unset", color: grey[700] }}
                    onClick={() => enhanceQuestion(question)}
                  >
                    <AutoFixHigh />
                  </Button>
                </span>
              </Tooltip>
            )}
            <RightCallout x={orientation.x} y={orientation.y} axis={orientation.axis} placement={orientation.placement}>
              <Button
                disableElevation
                disabled={!question}
                variant="contained"
                aria-label="search"
                sx={{ padding: "8px", margin: "4px", minWidth: "unset", color: grey[700] }}
                onClick={() => handleSubmitQuestion(question)}
              >
                <ArrowForward />
              </Button>
            </RightCallout>
          </Box>
        )}
      </Box>
    </Paper>
  );
}
