export const RouteId = {
  Root: "root",
  Deepchat: {
    Root: "deepchat",
    AskQuestion: "deepchat.ask-question",
    SessionThread: "deepchat.session-thread",
    SummarizeDocument: "deepchat.summarize-document",
  },
  Explore: { Root: "explore" },
  Retros: { Root: "retros" },
  Dashboards: {
    Root: "dashboards.custom",
  },
};
