import { Navigate, RouteObject } from "react-router-dom";
import AuthenticationWrapper from "./AuthenticationWrapper";
import { Authorization } from "./components/Authorization";
import { AskQuestion } from "./components/Deepchat/AskQuestion";
import { SessionThread } from "./components/Deepchat/SessionThread";
import { ErrorPage, HomePage } from "./pages";
import AppLayout from "./pages/AppLayout";
import { CustomDashboard } from "./pages/CustomDashboard";
import DeepchatPage from "./pages/DeepchatPage";
import RetrosPage from "./pages/RetrosPage";
import { RouteId } from "./RouteId";
import SummarizeDocument from "./components/Deepchat/SummarizeDocument/SummarizeDocument";

export const FlattenRoutes = (routes: RouteObject[], flatRoutes: RouteObject[] | undefined = undefined) => {
  flatRoutes = flatRoutes ?? [];

  routes.forEach((route) => {
    if (route.id) {
      flatRoutes.push(route);
    }
    if (route.children) {
      FlattenRoutes(route.children, flatRoutes);
    }
  });

  return flatRoutes;
};

export const FindRouteById = (routes: RouteObject[], id: string) => {
  const routesWithIds = FlattenRoutes(routes);
  const route = routesWithIds.find((_route) => _route.id === id);

  if (!route?.path) {
    console.error({ route });
    throw new Error(`Route ID "${id}" either does not exist or does not have a valid path defined.`);
  }

  return route;
};

export const Routes: RouteObject[] = [
  {
    path: "/",
    element: <AuthenticationWrapper />,
    errorElement: <ErrorPage />,
    id: RouteId.Root,
    children: [
      {
        path: "/auth",
        element: <Authorization />,
      },
      {
        path: "/",
        element: <AppLayout />,
        children: [
          {
            element: <Navigate replace to="/deepchat" />,
          },
          {
            path: "/explorer",
            element: <HomePage />,
            id: RouteId.Explore.Root,
          },
          {
            path: "/deepchat",
            element: <DeepchatPage />,
            id: RouteId.Deepchat.Root,
            children: [
              {
                path: "/deepchat",
                element: <AskQuestion />,
                id: RouteId.Deepchat.AskQuestion,
              },
              {
                path: "/deepchat/threads/:id?",
                element: <SessionThread />,
                id: RouteId.Deepchat.SessionThread,
              },
              {
                path: "/deepchat/summarize",
                element: <SummarizeDocument />,
                id: RouteId.Deepchat.SummarizeDocument,
              },
            ],
          },
          {
            path: "/dashboards/retros",
            element: <RetrosPage />,
            id: RouteId.Retros.Root,
          },
          {
            // Could make sense as a child to dashboards but I think
            // medinfo is going away
            path: "/dashboards/custom/:id",
            element: <CustomDashboard />,
            id: RouteId.Dashboards.Root,
            children: [
              {
                path: "edit",
                element: <CustomDashboard />,
              },
            ],
          },
        ],
      },
    ],
  },
];
